<template>
  <section class="login">
    <div class="login-logo">
      <img
        src="@/assets/images/logo-big.svg"
        alt="Logo"
        :width="$isMobile ? 170 : 198"
        :height="$isMobile ? 124 : 124"
      />
    </div>
    <form class="login-form" @submit.prevent="login">
      <span v-if="errorMsg" class="login-error">
        {{ errorMsg }}
      </span>
      <div
        class="login-username"
        :style="{
          marginTop: errorMsg ? '16px' : `${$isMobile ? '58px' : '50px'}`,
        }"
      >
        <base-input
          input-type="text"
          :id="'login'"
          :error="error"
          :placeholder="'Логин'"
          :required="true"
          @input="(errorMsg = ''), (error = fasle)"
          v-model="username"
        />
      </div>
      <div class="login-password">
        <base-input
          input-type="password"
          :id="'password'"
          :error="error"
          :placeholder="'Пароль'"
          :required="true"
          @input="(errorMsg = ''), (error = fasle)"
          v-model="password"
        />
      </div>
      <div class="login-signIn">
        <base-button type="filled"> Войти </base-button>
      </div>
      <span class="login-report">
        Если забыли пароль, обратитесь к администратору
      </span>
    </form>
  </section>
</template>

<script>
import { useMeta } from "vue-meta";

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      errorMsg: "",
      error: false,
    };
  },
  setup() {
    useMeta({ title: "Войти" });
  },
  methods: {
    login() {
      const data = {
        username: this.username,
        password: this.password,
      };
      this.$api
        .post(`${this.$api.defaults.API}/login/`, data)
        .then((res) => {
          // get token from login request
          const token = res.data.token;
          // get user
          this.$api
            .get(`${this.$api.defaults.API}/user/me`, {
              headers: {
                Authorization: `Token ${token}`,
              },
            })
            .then((res) => {
              const user = res.data;
              user.token = token;
              user.loggedAt = new Date();
              this.$store.dispatch("setUser", user);
              localStorage.setItem("user", JSON.stringify(user));
              this.$api.defaults.headers.Authorization = `Token ${user.token}`;
              let name = this.$route.next || "index";
              this.$router.push({ name: name });
            })
            .catch((err) => {
              new Error(err);
            });
        })
        .catch((err) => {
          if (err?.response?.data) {
            this.errorMsg = "Неверный Логин или Пароль";
            this.error = true;
          }
          new Error(err);
        });
    },
  },
};
</script>
